<template>
  <div style="padding: 10px">
    <div style="background: #fff; border-radius: 8px; padding: 20px">
      <div class="query-c">
        <Row>
          <Col span="3">
            <Input
              v-model="shopName"
              clearable
              placeholder="收单渠道"
              style="width: 150px"
            />
          </Col>
          <Col span="3">
            <Select
              v-model="organizationName"
              filterable
              clearable
              placeholder="选择机构"
              style="width: 150px"
            >
              <Option
                v-for="item in organizationList"
                :value="item.organizationName"
                :key="item.orId"
                >{{ item.organizationName }}</Option
              >
            </Select>
          </Col>
          <Col span="3">
            <DatePicker
              :value="dataList"
              @on-change="dateHandleChange"
              format="yyyy/MM/dd"
              type="date"
              placement="bottom-end"
              placeholder="开始日期"
              style="width: 150px"
            ></DatePicker>
          </Col>
          <Col span="3">
            <div>
              <DatePicker
                :value="dataList"
                @on-change="dateEndHandleChange"
                format="yyyy/MM/dd"
                type="date"
                placement="bottom-end"
                placeholder="结束日期"
                style="width: 150px"
              ></DatePicker>
            </div>
          </Col>
          <Col span="3">
            <Select
              v-model="uid"
              filterable
              clearable
              placeholder="客服"
              style="width: 150px"
            >
              <Option
                v-for="item in userList"
                :value="item.uid"
                :key="item.uid"
                >{{ item.user_name }}</Option
              >
            </Select>
          </Col>
          <Col span="3">
            <Select
              v-model="ctId"
              filterable
              clearable
              placeholder="车辆类型"
              style="width: 150px"
            >
              <Option
                v-for="item in carTypeList"
                :value="item.ctId"
                :key="item.ctId"
                >{{ item.name }}</Option
              >
            </Select>
          </Col>
          <Col span="6" push="2">
            <Button
              type="primary"
              icon="md-search"
              @click="findSalesBusinessList"
              >查询</Button
            >
            <Button
              type="primary"
              style="margin-left: 20px"
              icon="ios-git-merge"
              @click="updateTimeAct"
              >修改时间</Button
            >
          </Col>
        </Row>
        <Row>
          <Col span="3">
            <Input
              v-model="carNo"
              clearable
              placeholder="车牌号码"
              style="width: 150px"
            />
          </Col>
          <Col span="3">
            <Select
              v-model="source"
              filterable
              clearable
              placeholder="客户来源"
              style="width: 150px"
            >
              <Option
                v-for="item in sourceList"
                :value="item.source"
                :key="item.sid"
                >{{ item.source }}</Option
              >
            </Select>
          </Col>
          <Col span="3">
            <Input
              v-model="bdName"
              clearable
              placeholder="车主姓名"
              style="width: 150px"
            />
          </Col>
          <Col span="3">
            <Select
              v-model="js"
              filterable
              clearable
              placeholder="结算情况"
              style="width: 150px"
            >
              <Option value="0">未处理</Option>
              <Option value="1">已处理</Option>
            </Select>
          </Col>
          <Col span="3">
            <Select
              v-model="companyName"
              filterable
              clearable
              placeholder="选择公司"
              style="width: 150px"
            >
              <Option
                v-for="item in companyList"
                :value="item.companyName"
                :key="item.cid"
                >{{ item.companyName }}</Option
              >
            </Select>
          </Col>
          <Col span="3">
            <div style="color: #fff">.</div>
          </Col>
          <Col span="6" push="2">
            <Button
              type="success"
              icon="ios-download-outline"
              @click="downloadExcel"
              >导出</Button
            >
            <Button
              type="error"
              style="margin-left: 20px"
              icon="ios-trash-outline"
              @click="delBusinessByIds"
              >批量删除</Button
            >
          </Col>
        </Row>
      </div>

      <!-- <Checkbox-group v-model="tableColumnsChecked" @on-change="changeTableColumns">
        <Checkbox label="shopName">门店</Checkbox>
        <Checkbox label="companyName">公司</Checkbox>
        <Checkbox label="carNo">车牌号</Checkbox>
        <Checkbox label="bdName">保单姓名</Checkbox>
        <Checkbox label="sy">商业险</Checkbox>
        <Checkbox label="jq">交强险</Checkbox>
        <Checkbox label="ccs">车船税</Checkbox>
        <Checkbox label="jy">驾意险</Checkbox>
        <Checkbox label="consumerName">商业险机构</Checkbox>
        <Checkbox label="jqOrg">交强/车船税机构</Checkbox>
        <Checkbox label="jyOrg">驾意险机构</Checkbox>
        <Checkbox label="rebateAmount">返点金额</Checkbox>
        <Checkbox label="rebate">返点详情</Checkbox>
        <Checkbox label="yk">盈亏</Checkbox>
        <Checkbox label="jyRebateAmount">驾意险返点</Checkbox>
        <Checkbox label="jyRebate">驾意返点详情</Checkbox>
        <Checkbox label="jyYk">驾意盈亏</Checkbox>
        <Checkbox label="phone">联系方式</Checkbox>
        <Checkbox label="createTime">日期</Checkbox>
        <Checkbox label="source">来源</Checkbox>
        <Checkbox label="remark">备注</Checkbox>
    </Checkbox-group> -->
      <div class="sum">
        <div class="item">
          商业险:
          <span>{{ sumVo.sy || 0 | toFiexd2 }}元</span>
        </div>
        <div class="item">
          交强险:
          <span>{{ sumVo.jq || 0 | toFiexd2 }}元</span>
        </div>
        <div class="item">
          车船税:
          <span>{{ sumVo.ccs || 0 | toFiexd2 }}元</span>
        </div>
        <div class="item">
          非车:
          <span>{{ sumVo.jy || 0 | toFiexd2 }}元</span>
        </div>
        <div class="item">
          总保费:
          <span
            >{{
              ((Number(sumVo.jy) || 0) +
                (Number(sumVo.jq) || 0) +
                (Number(sumVo.ccs) || 0) +
                (Number(sumVo.sy) || 0))
                | toFiexd2
            }}
            元</span
          >
        </div>
        <!-- <div class="item">返点金额:
          <span>{{sumVo.rebateAmount || 0}}元</span>
        </div> -->
        <div class="item">
          应收返点:
          <span>{{ sumVo.jyRebateAmount || 0 | toFiexd2 }} 元</span>
        </div>
        <div class="item">
          应付金额:
          <span
            >{{
              ((Number(sumVo.jyYk) || 0) + (Number(sumVo.rebateAmount) || 0))
                | toFiexd2
            }}元</span
          >
        </div>
        <div class="item">
          盈亏:
          <span>{{ sumVo.yk || 0 | toFiexd2 }}元</span>
        </div>
        <div class="item">
          垫付:
          <span>{{ sumVo.df || 0 | toFiexd2 }}元</span>
        </div>
      </div>
      <Table
        ref="selection"
        @on-selection-change="selectChange"
        :max-height="maxHeight"
        stripe
        :columns="columns"
        :data="businessList"
        context-menu
        show-context-menu
        @on-contextmenu="handleContextMenu"
        size="small"
      ></Table>
      <Page
        :total="total"
        @on-change="changePage"
        @on-page-size-change="changePageNum"
        show-sizer
        show-elevator
      />
    </div>
    <Modal
      v-model="delModel"
      title="删除"
      @on-ok="delOk"
      @on-cancel="delCancel"
    >
      <p>确定删除？</p>
    </Modal>

    <Modal
      v-model="updateModifyTimeModel"
      title="修改时间"
      @on-ok="updateTimeOk"
      @on-cancel="updateTimeCancel"
    >
      <div>
        <span>时间</span>
        <DatePicker
          type="datetime"
          @on-change="updateHandleChange"
          :value="updateTime"
          show-week-numbers
          placeholder="Select date"
          style="width: 300px"
        ></DatePicker>
      </div>
    </Modal>
    <Modal
      v-model="updateModel"
      title="修改"
      width="1000"
      @on-ok="updateOk"
      @on-cancel="updateCancel"
      @on-visible-change="onVisibleChange"
    >
      <div class="form-body" :model="formValidate">
        <div class="form-item" prop="shopName">
          <div class="item">
            <span class="form-text">收单渠道</span
            ><Input
              v-model="formValidate.shopName"
              placeholder="请输入门店"
              style="width: 300px"
            />
          </div>
          <div class="item">
            <span class="form-text">保险公司</span>
            <Select
              v-model="formValidate.companyName"
              filterable
              clearable
              style="width: 300px"
            >
              <Option
                v-for="item in companyList"
                :value="item.companyName"
                :key="item.cid"
                >{{ item.companyName }}</Option
              >
            </Select>
          </div>
        </div>
        <div class="form-item">
          <div class="item">
            <span class="form-text">车牌号码</span
            ><Input
              v-model="formValidate.carNo"
              placeholder="请输入公司"
              style="width: 300px"
            />
          </div>
          <div class="item">
            <span class="form-text">联系方式</span
            ><Input
              v-model="formValidate.phone"
              placeholder="请输入联系方式"
              style="width: 300px"
            />
          </div>
        </div>
        <div class="form-item">
          <div class="item">
            <span class="form-text">车主姓名</span
            ><Input
              v-model="formValidate.bdName"
              placeholder="请输入车主姓名"
              style="width: 300px"
            />
          </div>
          <div class="item">
            <span class="form-text">机构</span>
            <!-- <span class="form-text">机构</span><Input v-model="formValidate.consumerName" placeholder="请输入机构名称" style="width: 300px" /> -->
            <Select
              v-model="formValidate.consumerName"
              filterable
              clearable
              style="width: 300px"
            >
              <Option
                v-for="item in organizationList"
                :value="item.organizationName"
                :key="item.orId"
                >{{ item.organizationName }}</Option
              >
            </Select>
          </div>
        </div>
        <div class="form-item">
          <div class="item">
            <span class="form-text">商业险</span
            ><Input
              type="number"
              v-model="formValidate.sy"
              placeholder="请输入商业险"
              style="width: 300px"
            />
          </div>
          <div class="item">
            <span class="form-text">交强险</span
            ><Input
              type="number"
              v-model="formValidate.jq"
              placeholder="请输入交强险"
              style="width: 300px"
            />
          </div>
        </div>
        <div class="form-item">
          <div class="item">
            <span class="form-text">车船税</span
            ><Input
              type="number"
              v-model="formValidate.ccs"
              placeholder="请输入车船税"
              style="width: 300px"
            />
          </div>
          <div class="item">
            <span class="form-text">非车</span
            ><Input
              type="number"
              v-model="formValidate.jy"
              placeholder="请输入金额"
              style="width: 300px"
            />
          </div>
          <!-- <div class="item">
               <span class="form-text">金额</span><Input  type="number" v-model="formValidate.je1" placeholder="请输入金额" style="width: 300px" />
             </div> -->
        </div>
        <!-- <div class="form-item">
               <div class="item">
                  <span class="form-text">交强/车船机构</span>
                   <Select v-model="formValidate.jqOrg" filterable clearable  style="width:300px">
                      <Option v-for="item in organizationList" :value="item.organizationName" :key="item.orId">{{ item.organizationName }}</Option>
                   </Select>
               </div>
                <div class="item">
                  <span class="form-text">驾意险机构</span>
                    <Select v-model="formValidate.jyOrg" filterable clearable  style="width:300px">
                      <Option v-for="item in organizationList" :value="item.organizationName" :key="item.orId">{{ item.organizationName }}</Option>
                    </Select>
                </div>
           </div>   -->
        <div class="form-item">
          <div class="item">
            <span class="form-text">业务员</span>
            <Select
              v-model="formValidate.source"
              filterable
              clearable
              style="width: 300px"
            >
              <Option
                v-for="item in sourceList"
                :value="item.source"
                :key="item.sid"
                >{{ item.source }}</Option
              >
            </Select>
            <!-- <Input v-model="formValidate.source" re placeholder="请输入备注信息" style="width: 300px" /> -->
          </div>
          <div class="item">
            <span class="form-text">车辆类型</span>
            <Select
              v-model="formValidate.ctId"
              filterable
              clearable
              style="width: 300px"
            >
              <Option
                v-for="item in carTypeList"
                :value="item.ctId"
                :key="item.ctId"
                >{{ item.name }}</Option
              >
            </Select>
          </div>
        </div>
        <div class="form-item">
          <div class="item">
            <span class="form-text">应收返点</span>
            <Input
              type="number"
              v-model="calcYs"
              :readonly="true"
              placeholder="请输入金额"
              style="width: 300px"
            />
          </div>
          <div class="item">
            <span class="form-text">应付返点</span
            ><Input
              :readonly="true"
              type="number"
              v-model="calcYf"
              placeholder="请输入金额"
              style="width: 300px"
            />
          </div>
        </div>
        <div class="form-item">
          <div class="item">
            <span class="form-text">垫付金额</span
            ><Input
              type="number"
              v-model="formValidate.df"
              placeholder="请输入盈亏"
              style="width: 300px"
            />
          </div>
          <div class="item">
            <span class="form-text">返点金额</span
            ><Input
              type="number"
              v-model="formValidate.rebateAmount"
              placeholder="请输入返点金额"
              style="width: 300px"
            />
          </div>
        </div>
        <div class="form-item">
          <div class="item">
            <span class="form-text">盈亏</span
            ><Input
              :readonly="true"
              type="number"
              v-model="calcYk"
              placeholder="请输入盈亏"
              style="width: 300px"
            />
          </div>
          <div class="item">
            <span class="form-text">备注</span
            ><Input
              v-model="formValidate.remark"
              placeholder="请输入备注信息"
              style="width: 300px"
            />
          </div>
        </div>
        <!-- <div class="form-item">
             <div class="item">
               <span class="form-text">驾意盈亏</span><Input disabled  type="number" v-model="formValidate.jyYk" placeholder="请输入金额" style="width: 300px" />
             </div>
             <div class="item">
               <span class="form-text">驾意返点</span><Input disabled  type="number" v-model="formValidate.jyRebateAmount" placeholder="请输入金额" style="width: 300px" />
             </div>
           </div> -->

        <div class="form-item" style="height: 100px; padding-top: 30px">
          <div class="item">
            <span class="form-text" style="height: 100px; line-height: 100px"
              >应收比例</span
            >
            <div class="item-input">
              <div>商比例</div>
              <Input
                v-model="rebateInfo.e"
                type="number"
                placeholder=""
                style="width: 80px"
              />
            </div>
            <div class="item-input">
              <div>交比例</div>
              <Input
                v-model="rebateInfo.f"
                type="number"
                placeholder=""
                style="width: 80px"
              />
            </div>

            <div class="item-input">
              <div>税比例</div>
              <Input
                v-model="rebateInfo.g"
                type="number"
                placeholder=""
                style="width: 80px"
              />
            </div>
            <div class="item-input">
              <div>非车比例</div>
              <Input
                v-model="rebateInfo.h"
                type="number"
                placeholder=""
                style="width: 80px"
              />
            </div>
          </div>
        </div>
        <div class="form-item" style="height: 100px; padding-top: 30px">
          <div class="item">
            <span class="form-text" style="height: 100px; line-height: 100px"
              >应付比例</span
            >
            <div class="item-input">
              <div>商比例</div>
              <Input
                v-model="rebateInfo.a"
                type="number"
                placeholder=""
                style="width: 80px"
              />
            </div>
            <div class="item-input">
              <div>交比例</div>
              <Input
                v-model="rebateInfo.b"
                type="number"
                placeholder=""
                style="width: 80px"
              />
            </div>
            <div class="item-input">
              <div>税比例</div>
              <Input
                v-model="rebateInfo.c"
                type="number"
                placeholder=""
                style="width: 80px"
              />
            </div>
            <div class="item-input">
              <div>非车比例</div>
              <Input
                v-model="rebateInfo.d"
                type="number"
                placeholder=""
                style="width: 80px"
              />
            </div>
            <!-- <div class="item-input">
                  <div style="height:100px;line-height: 100px;">
                      <Button type="primary" @click="calc()">计算</Button>
                  </div>
              </div> -->
          </div>
        </div>
      </div>
    </Modal>
    <Modal title="" v-model="visible">
      <div class="img" v-if="[1,2].indexOf(showTag)!=-1">
        <img :src="item" v-for="(item,index) in imgArr"  :key="index" style="width: 100%">
      </div>
      <div class="pdf" v-if="[3].indexOf(showTag)!=-1">
        <Button type="primary" size="small" @click="showPdf(item)"  v-for="(item,index) in imgArr" :key="index">下载保单{{ index+1 }}</Button>
      </div>
    </Modal>
    <template slot="contextMenu">
      <DropdownItem @click.native="handleContextMenuEdit">编辑</DropdownItem>
      <DropdownItem
        @click.native="handleContextMenuDelete"
        style="color: #ed4014"
        >删除</DropdownItem
      >
    </template>
  </div>
</template>

<script>
// eslint-disable-next-line max-len
import {
  findBusinessVoList,
  findCompanyVoList,
  delBusiness,
  findSourceList,
  findOrganizationList,
  updateBusinessVo,
  delBusinessByIds,
  updateModifyTime,
  findAllUserNameList,
  findCarTypeList,
} from '@/api'

let moment = require('moment')
// require
export default {
  name: 'DataStatistics',
  data() {
    return {
      showTag: '',
      visible: false,
      imgArr: [],
      carTypeList: [],
      ctId: '',
      maxHeight: 800,
      pageIndex: 1,
      pageSize: 10,
      pageNum: 10,
      total: 100,
      shopName: '',
      companyName: '',
      companyList: [],
      businessList: [],
      dataList: '',
      startTime: new Date(new Date().toLocaleDateString()).getTime(),
      endTime: 0,
      delModel: false,
      delbid: '',
      sourceList: [],
      userList: [],
      uid: '',
      source: '',
      organizationList: [],
      organizationName: '',
      carNo: '',
      bdName: '',
      delArr: [],
      updateModel: false,
      updateModifyTimeModel: false,
      updateTime: new Date(),
      selectArr: [],
      js: '',
      formValidate: {
        ctId: '',
        shopName: '',
        companyName: '',
        carNo: '',
        consumerName: '',
        bdName: '',
        sy: 0,
        jq: 0,
        ccs: 0,
        jy: 0,
        je1: 0,
        payTatol: 0,
        rebate: '',
        yk: 0,
        phone: '',
        rebateAmount: 0.0,
        remark: '',
        source: '',
        jyYk: 0,
        jyRebateAmount: 0,
        jyRebate: '',
        jqOrg: '',
        jyOrg: '',
      },
      rebateInfo: {
        a: 0,
        b: 0,
        c: 0,
        d: 0,
        e: 0,
        f: 0,
        g: 0,
        h: 0,
      },
      tableColumnsChecked: [
        'companyName',
        'bdName',
        'phone',
        'carNo',
        'createTime',
        'consumerName',
        'shopName',
        'sy',
        'jq',
        'ccs',
        'jy',
        'sum',
        'syRate',
        'jqRate',
        'ccsRate',
        'jyRate',
        'jyRebateAmount',
        'js',
        'syRate2',
        'jqRate2',
        'ccsRate2',
        'jyRate2',
        // 'jqOrg',
        // 'jyOrg',
        'jyYk',
        // 'rebate',
        // 'jyRebate',

        'rebateAmount',
        'yk',
        'df',
        'source',
        'userName',
        'carType',
        'xsz',
        'jsz',
        'bd',
        'remark',
      ],
      columns: [
        // {
        //       type: 'selection',
        //       width: 60,
        //       align: 'center',
        //   },
        //   {
        //     type: 'index',
        //     width: 60,
        //     align: 'center',
        //   },
        //   {
        //       title: '收单渠道',
        //       key: 'shopName',
        //       width: 150,
        //       resizable: true,
        //   },
        //    {
        //       title: '保险公司',
        //       key: 'companyName',
        //       resizable: true,
        //       width: 150,
        //   },
        //   {
        //       title: '车牌号',
        //       key: 'carNo',
        //       resizable: true,
        //       width: 150,
        //   },
        //   {
        //       title: '保单姓名',
        //       key: 'bdName',
        //       sortable: true,
        //       width: 150,
        //   },
        //   {
        //       title: '商业险',
        //       key: 'sy',
        //       sortable: true,
        //       width: 100,
        //   },
        //   {
        //       title: '交强险',
        //       key: 'jq',
        //       sortable: true,
        //       width: 100,
        //   },
        //   {
        //       title: '车船税',
        //       key: 'ccs',
        //       sortable: true,
        //        width: 100,
        //   },
        //   {
        //       title: '非车',
        //       key: 'jy',
        //       sortable: true,
        //       width: 100,
        //   },
      ],
      sumVo: {
        sy: 0,
        jq: 0,
        ccs: 0,
        rebateAmount: 0,
        jyRebateAmount: 0,
        jyYk: 0,
        yk: 0,
        df: 0,
      },
    }
  },
  mounted() {
    // 设置表格高度
    const t = this
    t.maxHeight = window.innerHeight - 320
    t.findOrganizationList()
    t.findSourceList()
    t.findCompanyVoList()
    t.findSalesBusinessList()
    t.changeTableColumns()
    t.findUserList()
    t.findCarTypeList()
  },
  created() {},
  computed: {
    // 计算应收返点
    calcYs() {
      let t = this
      const sy = t.formValidate.sy
      const jq = t.formValidate.jq
      const ccs = t.formValidate.ccs
      const jy = t.formValidate.jy

      const data = t.rebateInfo
      const e = data.e === 0 ? 0 : data.e / 100
      const f = data.f === 0 ? 0 : data.f / 100
      const g = data.g === 0 ? 0 : data.g / 100
      const h = data.h === 0 ? 0 : data.h / 100
      return (
        parseFloat(sy * e)
        + parseFloat(jq * f)
        + parseFloat(ccs * g)
        + parseFloat(jy * h)
      ).toFixed(2)
    },
    // 计算应付返点
    calcYf() {
      const t = this
      const data = t.rebateInfo

      const sy = t.formValidate.sy
      const jq = t.formValidate.jq
      const ccs = t.formValidate.ccs
      const jy = t.formValidate.jy

      const a = data.a === 0 ? 0 : data.a / 100
      const b = data.b === 0 ? 0 : data.b / 100
      const c = data.c === 0 ? 0 : data.c / 100
      const d = data.d === 0 ? 0 : data.d / 100

      return (
        parseFloat(sy * a)
        + parseFloat(jq * b)
        + parseFloat(ccs * c)
        + parseFloat(jy * d)
      ).toFixed(2)
    },
    // 计算盈亏
    calcYk() {
      let t = this
      const sy = t.formValidate.sy
      const jq = t.formValidate.jq
      const ccs = t.formValidate.ccs
      const jy = t.formValidate.jy

      const data = t.rebateInfo
      const e = data.e === 0 ? 0 : data.e / 100
      const f = data.f === 0 ? 0 : data.f / 100
      const g = data.g === 0 ? 0 : data.g / 100
      const h = data.h === 0 ? 0 : data.h / 100

      const a = data.a === 0 ? 0 : data.a / 100
      const b = data.b === 0 ? 0 : data.b / 100
      const c = data.c === 0 ? 0 : data.c / 100
      const d = data.d === 0 ? 0 : data.d / 100

      let ys = parseFloat(sy * e)
        + parseFloat(jq * f)
        + parseFloat(ccs * g)
        + parseFloat(jy * h)
      let yf = parseFloat(sy * a)
        + parseFloat(jq * b)
        + parseFloat(ccs * c)
        + parseFloat(jy * d)

      let fd = t.formValidate.rebateAmount
      return Number(ys - yf - fd).toFixed(2)
    },
  },
  methods: {
    findCarTypeList() {
      let data = 'pageSize=10000&pageIndex=1'
      findCarTypeList(data).then((res) => {
        this.carTypeList = res.data.list
      })
    },
    handleReset() {
      const keys = Object.keys(this.formValidate)
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]
        if (this.formValidate[key]) {
          if (this.formValidate[key].constructor == Number) {
            this.formValidate[key] = 0
          }
          if (this.formValidate[key].constructor == String) {
            this.formValidate[key] = ''
          }
        }
      }
      this.rebateInfo = {
        a: 0,
        b: 0,
        c: 0,
        d: 0,
        e: 0,
        f: 0,
        g: 0,
        h: 0,
      }
    },
    findUserList() {
      let params = 'pageIndex=1&pageSize=10000'
      findAllUserNameList(params).then((res) => {
        this.userList = res.data
      })
    },
    findSalesBusinessList() {
      let data = 'pageSize='
        + this.pageSize
        + '&pageIndex='
        + this.pageIndex
        + '&startTime='
        + this.startTime
      if (this.endTime === 0) {
        data += '&endTime=' + new Date().getTime()
      } else {
        data += '&endTime=' + this.endTime
      }
      if (this.js) {
        data += `&js=${this.js}`
      }
      if (this.companyName && this.companyName !== '') {
        data += '&companyName=' + this.companyName
      }
      if (this.carNo && this.carNo !== '') {
        data += '&carNo=' + this.carNo
      }
      if (this.bdName && this.bdName !== '') {
        data += '&bdName=' + this.bdName
      }
      if (this.source && this.source !== '') {
        data += '&source=' + this.source
      }
      if (this.organizationName && this.organizationName !== '') {
        data += '&organization=' + this.organizationName
      }
      if (this.shopName.length > 0) {
        data += '&shopName=' + this.shopName
      }
      if (this.uid !== undefined && this.uid !== '') {
        data += '&uid=' + this.uid
      }
      if (this.ctId && this.ctId !== '') {
        data += '&ctId=' + this.ctId
      }
      findBusinessVoList(data).then((res) => {
        this.businessList = res.data.list
        this.total = res.data.count
        this.sumVo = res.data.total
      })
    },
    findCompanyVoList() {
      findCompanyVoList().then((res) => {
        this.companyList = res.data
      })
    },
    changePageNum(size) {
      this.pageSize = size
      this.findSalesBusinessList()
    },
    changePage(p) {
      this.pageIndex = p
      this.findSalesBusinessList()
    },
    dateHandleChange(date) {
      if (date !== undefined && date !== '') {
        this.startTime = moment(date, 'YYYY/MM/DD').valueOf()
      } else {
        this.startTime = new Date(new Date().toLocaleDateString()).getTime()
      }
    },
    dateEndHandleChange(date) {
      if (date !== undefined && date !== '') {
        this.endTime = moment(date, 'YYYY/MM/DD').valueOf()
      } else {
        this.endTime = new Date(new Date().toLocaleDateString()).getTime()
      }
      this.endTime += 24 * 60 * 60 * 1000 - 1
    },
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    remove(row) {
      this.delModel = true
      this.delbid = row.bid
    },
    delOk() {
      delBusiness(this.delbid).then(() => {
        this.$Message.success('操作成功')
        this.findSalesBusinessList()
      })
    },
    delCancel(row) {
      console.log(row)
    },
    update(params) {
      this.formValidate = { ...params.row }
      let jyRebate = params.row.jyRebate
      //  let arr1 = []
      // if (rebate.indexOf('-') != -1) {
      //    let arr = rebate.split('-')
      //    for (let i = 0; i < arr.length; i++) {
      //         let arr2 = arr[i].split('/')
      //         for (let j = 0; j < arr2.length; j++) {
      //             arr1.push(arr2[j])
      //         }
      //    }
      // }
      // if (jyRebate.indexOf('-') != -1) {
      //    let arr = jyRebate.split('-')
      //    for (let i = 0; i < arr.length; i++) {
      //         let arr2 = arr[i].split('/')
      //         for (let j = 0; j < arr2.length; j++) {
      //             arr1.push(arr2[j])
      //         }
      //    }
      // }
      //  this.rebateInfo.a = arr1[0]
      //  this.rebateInfo.b = arr1[1]
      //  this.rebateInfo.c = arr1[2]
      //  this.rebateInfo.d = arr1[6]
      //  this.rebateInfo.e = arr1[3]
      //  this.rebateInfo.f = arr1[4]
      //  this.rebateInfo.g = arr1[5]
      //  this.rebateInfo.h = arr1[7]
      if (jyRebate) {
        this.rebateInfo = JSON.parse(jyRebate)
      }
      this.updateModel = true
    },
    calc() {
      const t = this
      const data = t.rebateInfo
      const sy = t.formValidate.sy
      const jq = t.formValidate.jq
      const ccs = t.formValidate.ccs
      const jy = t.formValidate.jy

      const a = data.a === 0 ? 0 : data.a / 100
      const b = data.b === 0 ? 0 : data.b / 100
      const c = data.c === 0 ? 0 : data.c / 100
      const d = data.d === 0 ? 0 : data.d / 100

      const e = data.e - data.a === 0 ? 0 : (data.e - data.a) / 100
      const f = data.f - data.b === 0 ? 0 : (data.f - data.b) / 100
      const g = data.g - data.c === 0 ? 0 : (data.g - data.c) / 100
      const h = data.h - data.d === 0 ? 0 : (data.h - data.d) / 100

      const fd = sy * a + jq * b + ccs * c
      const yk = sy * e + jq * f + ccs * g

      const jyFd = jy * d
      const jyYk = jy * h
      const rebate = data.a
        + '/'
        + data.b
        + '/'
        + data.c
        + '-'
        + data.e
        + '/'
        + data.f
        + '/'
        + data.g
      const jyRebate = data.d + '-' + data.h
      t.formValidate.rebateAmount = fd.toFixed(2)
      t.formValidate.yk = yk.toFixed(2)
      t.formValidate.rebate = rebate
      t.formValidate.jyRebateAmount = jyFd.toFixed(2)
      t.formValidate.jyYk = jyYk.toFixed(2)
      t.formValidate.jyRebate = jyRebate
    },
    updateOk() {
      let obj = { ...this.formValidate }
      delete obj.shopId
      delete obj.companyId
      // console.log(this.formValidate)
      if (!obj.consumerName) {
        obj.consumerName = ''
      }
      if (!obj.jqOrg) {
        this.formValidate.jqOrg = ''
      }
      if (!obj.jyOrg) {
        obj.jyOrg = ''
      }
      if (!obj.source) {
        obj.source = ''
      }
      if (!obj.companyName) {
        this.$Message.warning('请选择一个公司')
        return
      }
      if (!obj.ctId || obj.ctId === null) {
        obj.ctId = 0
      }
      if (
        !obj.rebateAmount
        || obj.rebateAmount === null
        || obj.rebateAmount.length === 0
      ) {
        obj.rebateAmount = 0.0
      }
      // 应收
      obj.jyRebateAmount = this.calcYs
      // 应付
      debugger
      obj.jyYk = this.calcYf
      obj.yk = this.calcYk
      // 保存返点比
      const data = this.rebateInfo
      obj.jyRebate = JSON.stringify(data)
      updateBusinessVo(obj).then(() => {
        this.findSalesBusinessList()
        this.$Message.success('操作成功')
      })
    },
    updateCancel() {},
    onVisibleChange(e) {
      if (!e) {
        this.handleReset()
      }
    },
    findSourceList() {
      let params = 'pageIndex=1&pageSize=10000'
      findSourceList(params).then((res) => {
        this.sourceList = res.data.list
      })
    },
    findOrganizationList() {
      let params = 'pageIndex=1&pageSize=10000'
      findOrganizationList(params).then((res) => {
        this.organizationList = res.data.list
      })
    },
    getTable2Columns() {
      const table2ColumnList = {
        selection: {
          type: 'selection',
          width: 60,
          align: 'center',
        },
        index: {
          type: 'index',
          width: 60,
          align: 'center',
        },
        shopName: {
          title: '收单渠道',
          key: 'shopName',
          width: 90,
          resizable: true,
        },
        companyName: {
          title: '保险公司',
          key: 'companyName',
          resizable: true,
          width: 90,
        },
        carNo: {
          title: '车牌号',
          key: 'carNo',
          width: 120,
        },
        bdName: {
          title: '车主姓名',
          key: 'bdName',
          tooltip: true,
          width: 100,
        },
        sy: {
          title: '商业险',
          key: 'sy',
          width: 90,
          render: (h, params) => h('span', {}, Number(params.row.sy).toFixed(2)),
        },
        jq: {
          title: '交强险',
          key: 'jq',
          width: 90,
          render: (h, params) => h('span', {}, Number(params.row.jq).toFixed(2)),
        },
        ccs: {
          title: '车船税',
          key: 'ccs',
          width: 90,
          render: (h, params) => h('span', {}, Number(params.row.ccs).toFixed(2)),
        },
        jy: {
          title: '非车',
          key: 'jy',
          width: 90,
          render: (h, params) => h('span', {}, Number(params.row.jy).toFixed(2)),
        },
        sum: {
          title: '总保费',
          width: 90,
          render: (h, params) => h(
              'span',
              {},
              (
                params.row.ccs
                + params.row.sy
                + params.row.jq
                + params.row.jy
              ).toFixed(2)
            ),
        },
        syRate: {
          title: '商比例',
          width: 90,
          render: (h, params) => h('span', {}, this.getRepate(params.row.jyRebate, 'e')),
        },
        jqRate: {
          title: '交比例',
          width: 90,
          render: (h, params) => h('span', {}, this.getRepate(params.row.jyRebate, 'f')),
        },
        ccsRate: {
          title: '税比例',
          width: 90,
          render: (h, params) => h('span', {}, this.getRepate(params.row.jyRebate, 'g')),
        },
        jyRate: {
          title: '非车比例',
          width: 90,
          render: (h, params) => h('span', {}, this.getRepate(params.row.jyRebate, 'h')),
        },
        js: {
          title: '结算情况',
          width: 90,
          render: (h, params) => h('span', {}, params.row.js === '1' ? '已结算' : '未结算'),
        },
        consumerName: {
          title: '机构',
          key: 'consumerName',
          resizable: true,
          tooltip: true,
          width: 140,
        },
        syRate2: {
          title: '商比例',
          width: 90,
          render: (h, params) => h('span', {}, this.getRepate(params.row.jyRebate, 'a')),
        },
        jqRate2: {
          title: '交比例',
          width: 90,
          render: (h, params) => h('span', {}, this.getRepate(params.row.jyRebate, 'b')),
        },
        ccsRate2: {
          title: '税比例',
          width: 90,
          render: (h, params) => h('span', {}, this.getRepate(params.row.jyRebate, 'c')),
        },
        jyRate2: {
          title: '非车比例',
          width: 90,
          render: (h, params) => h('span', {}, this.getRepate(params.row.jyRebate, 'd')),
        },
        // jqOrg: {
        //     title: '交强险/车船机构',
        //     key: 'jqOrg',
        //     sortable: true,
        //     width: 180,
        // },
        // jyOrg: {
        //     title: '驾意险机构',
        //     key: 'jyOrg',
        //     sortable: true,
        //     width: 180,
        // },
        rebateAmount: {
          title: '返点金额',
          key: 'rebateAmount',
          width: 90,
          render: (h, params) => h('span', {}, Number(params.row.rebateAmount).toFixed(2)),
        },
        // rebate: {
        //     title: '返点详情',
        //     key: 'rebate',
        //     sortable: true,
        //     width: 200,
        // },
        yk: {
          title: '盈亏',
          key: 'yk',
          width: 90,
          render: (h, params) => h('span', {}, Number(params.row.yk).toFixed(2)),
        },
        // jyRebate: {
        //     title: '非车返点详情',
        //     key: 'jyRebate',
        //     sortable: true,
        //     width: 150,
        // },
        jyYk: {
          title: '应付金额',
          width: 90,
          tooltip: true,
          render: (h, params) => h('span', {}, Number(params.row.jyYk).toFixed(2)),
        },
        jyRebateAmount: {
          title: '应收金额',
          key: 'jyRebateAmount',
          width: 90,
          tooltip: true,
          render: (h, params) => h('span', {}, Number(params.row.jyRebateAmount).toFixed(2)),
        },
        phone: {
          title: '联系方式',
          key: 'phone',
          width: 110,
        },
        createTime: {
          title: '日期',
          key: 'createTime',
          sortable: true,
          width: 140,
          render: (h, params) => h('div', moment(params.row.createTime).format('yyyy-MM-DD hh:mm')),
        },
        df: {
          title: '垫付金额',
          key: 'df',
          width: 90,
          render: (h, params) => h('span', {}, Number(params.row.df).toFixed(2)),
        },
        source: {
          title: '业务员',
          key: 'source',
          width: 90,
        },
        userName: {
          title: '运营人员',
          key: 'userNo',
          width: 90,
        },
        carType: {
          title: '车辆类型',
          key: 'carType',
          width: 90,
        },
        xsz: {
          title: '行驶证',
          key: 'xsz',
          width: 90,
          render: (h, params) => h('div', [
              h(
                'a',
                {
                  on: {
                    click: () => {
                      this.showImgs(1, params.row)
                    },
                  },
                },
                '查看'
              ),
            ]),
        },
        jsz: {
          title: '身份证',
          key: 'sfz',
          width: 90,
          render: (h, params) => h('div', [
              h(
                'a',
                {
                  on: {
                    click: () => {
                      this.showImgs(2, params.row)
                    },
                  },
                },
                '查看'
              ),
            ]),
        },
        bd: {
          title: '保单',
          key: 'bd',
          width: 90,
          render: (h, params) => h('div', [
              h(
                'a',
                {
                  on: {
                    click: () => {
                      this.showImgs(3, params.row)
                    },
                  },
                },
                '查看'
              ),
            ]),
        },
        remark: {
          title: '备注',
          key: 'remark',
          width: 150,
        },
        action: {
          title: '操作',
          key: 'action',
          width: 180,
          align: 'center',
          fixed: 'right',
          render: (h, params) => h('div', [
              h(
                'Button',
                {
                  props: {
                    type: 'success',
                    size: 'small',
                  },
                  style: {
                    marginRight: '5px',
                  },
                  on: {
                    click: () => {
                      this.jsAct(params)
                    },
                  },
                },
                '结算'
              ),
              h(
                'Button',
                {
                  props: {
                    type: 'primary',
                    size: 'small',
                  },
                  style: {
                    marginRight: '5px',
                  },
                  on: {
                    click: () => {
                      this.update(params)
                    },
                  },
                },
                '修改'
              ),
              h(
                'Button',
                {
                  props: {
                    type: 'error',
                    size: 'small',
                  },
                  on: {
                    click: () => {
                      this.remove(params.row)
                    },
                  },
                },
                '删除'
              ),
            ]),
        },
      }
      let data = [table2ColumnList.selection]
      data.push(table2ColumnList.index)
      this.tableColumnsChecked.forEach((col) => {
        data.push(table2ColumnList[col])
      })
      data.push(table2ColumnList.action)
      return data
    },
    changeTableColumns() {
      this.columns = this.getTable2Columns()
    },
    selectChange(select) {
      let t = this
      t.delArr = []
      for (let i = 0; i < select.length; i++) {
        t.delArr.push(select[i].bid)
      }
      t.selectArr = [...select]
    },
    delBusinessByIds() {
      let t = this
      if (!t.delArr || t.delArr.length == 0) {
        t.$Message.warning('请先勾选要删除项')
        return
      }
      delBusinessByIds(t.delArr).then(() => {
        t.$Message.success('操作成功')
        t.findSalesBusinessList()
      })
    },
    downloadExcel() {
      let url = `${process.env.VUE_APP_SERVER_URL}downloadExcel?`

      // const uid = localStorage.getItem('uid')
      let data = 'pageSize='
        + this.pageSize
        + '&pageIndex='
        + this.pageIndex
        + '&startTime='
        + this.startTime
      if (this.endTime == 0) {
        data += '&endTime=' + new Date().getTime()
      } else {
        data += '&endTime=' + this.endTime
      }
      if (this.js) {
        data += `$js=${this.js}`
      }
      if (this.companyName && this.companyName !== '') {
        data += '&companyName=' + this.companyName
      }
      if (this.carNo && this.carNo !== '') {
        data += '&carNo=' + this.carNo
      }
      if (this.bdName && this.bdName !== '') {
        data += '&bdName=' + this.bdName
      }
      if (this.source && this.source !== '') {
        data += '&source=' + this.source
      }
      if (this.organizationName && this.organizationName !== '') {
        data += '&organization=' + this.organizationName
      }
      if (this.shopName.length > 0) {
        data += '&shopName=' + this.shopName
      }
      window.open(url + data)
    },
    handleContextMenu(row) {
      const index = this.data1.findIndex((item) => item.name === row.name)
      this.contextLine = index + 1
    },
    handleContextMenuEdit() {
      this.$Message.info('Click edit of line' + this.contextLine)
    },
    handleContextMenuDelete() {
      this.$Message.info('Click delete of line' + this.contextLine)
    },
    updateTimeAct() {
      let t = this
      let select = t.selectArr
      if (select.length != 1) {
        t.$Message.warning('有且只能修改一条数据')
        return
      }
      this.updateTime = new Date(select[0].createTime)
      this.updateModifyTimeModel = true
    },
    updateHandleChange(t) {
      this.updateTime = new Date(moment(t, 'YYYY-MM-DD hh:mm:ss').valueOf())
      console.log(moment(t, 'YYYY-MM-DD hh:mm:ss'))
    },
    updateTimeOk() {
      let data = {
        bid: this.selectArr[0].bid,
        modifyTime: this.updateTime.valueOf(),
      }
      updateModifyTime(data).then(() => {
        this.updateModifyTimeModel = false
        this.findSalesBusinessList()
        this.$Message.success('操作成功')
      })
    },
    updateTimeCancel() {},
    jsAct(param) {
      let t = this
      t.$Modal.confirm({
        title: '结算确认',
        content: '此保单已经结算?',
        onOk() {
          let data = {
            bid: param.row.bid,
            js: '1',
          }
          updateBusinessVo(data).then((res) => {
            console.log(res)
            t.findSalesBusinessList()
            t.$Message.success('操作成功')
          })
        },
      })
    },
    getRepate(row, c) {
      if (row !== '' && row !== undefined) {
        return JSON.parse(row)[c]
      }
    },
     /**
       * 显示行驶证和驾驶证图片
       * @param {*} tag 行驶证 1 ,身份证 2
       * @param {*} row 行数据
       */
      showImgs(tag, row) {
      let urls = ''
      switch (tag) {
        case 1:
          urls = row.xsz || ''
          break
        case 2:
          urls = row.sfz || ''
          break
        case 3:
          urls = row.bd || ''
          break
        default: break
      }
      if (!urls) {
        this.$Notice.warning({
            title: '没有上传',
        })
        return
      }
      // if (tag === 3) {
      //   window.open(urls)
      //   return
      // }
      this.showTag = tag
      let arr = urls.split(',')
      this.imgArr = arr
      this.visible = true
    },
    showPdf(url) {
      window.open(url)
    },
  },
}
</script>

<style  lang="scss">
.add {
  position: absolute;
  right: 40px;
}
.search-bt {
  position: absolute;
  right: 140px;
}
.query-c {
  height: 70px;
}

.form-body {
  width: 100%;
}
.form-item {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  justify-items: center;
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    .item-input {
      width: 100px;
      text-align: center;
    }
  }
}
.button-item {
  width: 600px;
  height: 100px;
  line-height: 100px;
  margin: 0 auto;
}
.form-text {
  text-align: center;
  display: inline-block;
  width: 90px;
}

.ivu-select-selected-value {
  width: 100%;
}
.sum {
  width: 100%;
  display: flex;
  flex-direction: row;
  .item {
    color: #0000ff;
    margin-right: 10px;
    span {
      color: #8d0926;
    }
  }
}
</style>